import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "theme/apo_torchlogo.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)
  return (
    <img
      src={data.logoImage.childImageSharp.original.src}
      alt="Logo for Alpha Phi Omega"
    />
  )
};

export default Logo;