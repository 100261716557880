import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from './logo';
import Navigation, {navLinks} from './navigation' 

const Header = ({ siteTitle }) => (
  <header className="site-header">
    <div className="layout-wrapper">
      <div className="site-logo">
        <Link to="/">
          <Logo />
          <div className="site-subhead">Epsilon Zeta<br />Alumni Association</div>
        </Link>
      </div>
      <Navigation navLinks={navLinks} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Alpha Phi Omega Epsilon Zeta Alumni Association`,
}

export default Header
