import React from 'react'
import {Link} from 'gatsby';

const navLinks = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'About EZAA',
    to: '/about',
  },
  {
    label: 'Find Us',
    to: '/find-us',
  },
  {
    label: 'Documents & Resources',
    to: '/docs',
  },
];

const Navigation = ({navLinks}) => (
  <nav className="site-navigation">
    <ul>
      {
        navLinks.map(link => (
          <li key={link.label}><Link to={link.to}>{link.label}</Link></li>
        ))
      }
    </ul>
  </nav>
)

export { navLinks };

export default Navigation
