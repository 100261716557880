import React from "react";

const Footer = () => (
  <div className="footer">
    <div
      style={{
        margin: "0 auto",
        maxWidth: "960px",
        padding: "1.45rem 1.0875rem",
      }}
    >
      <p>The Epsilon Zeta Alumni Association is affiliated with the Epsilon Zeta Chapter of Alpha Phi Omega, National Service Fraternity</p>
      <p>Rensselaer Polytechnic Institute, Troy, NY</p>
    </div>
  </div>
);

export default Footer;
